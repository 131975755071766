import React from 'react';
import { Navigate } from 'react-router-dom';

const WithAdminRestriction = (Component) => {
  return () => {
    // Get the user's role from local storage
    const role = localStorage.getItem('role');

    // Check if the user is an admin
    const isAdmin = role === 'admin';

    // Redirect if the user is not an admin
    if (!isAdmin) {
      return <Navigate to="/login" />; // Redirect to login or a restricted access page
    }

    // Render the component for admin users
    return <Component />;
  };
};

export default WithAdminRestriction;
