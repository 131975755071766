import axios from 'axios';
// Create an instance of Axios
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the token from localStorage
    const accessToken = localStorage.getItem('accessToken');
    // Modify config before sending the request
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
      //config.headers['Path'] = path;
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Modify response data before returning     
    return response;
  },
  (error) => {
    // Handle response error        
    return Promise.reject(error);
    
  }
);

export default axiosInstance;
