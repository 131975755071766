import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import 'bootstrap/dist/css/bootstrap.css';
import store from './store/store';
import App from './app';
import theme from './theme';

import { CssBaseline } from '@mui/material';

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_TANENT_ID}`,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  // Other configuration options                                                              
};

const pca = new PublicClientApplication(msalConfig);

const rootElement = document.getElementById('root');
ReactDOM.hydrate(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MsalProvider instance={pca}>
        <Provider store={store}>
          <CssBaseline />
          <Router>
            <App />
          </Router>
        </Provider>
      </MsalProvider>
    </ThemeProvider>
  </React.StrictMode>,
  rootElement
);
