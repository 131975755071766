import { createAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../api/axios';
import {
  clearGlobalLoader,
  setGlobalLoader,
  setAlert,
  dispatchErrorMessage,
} from './globalAppSlice';

export const fetchLayers = createAsyncThunk(
  '/api/get_all_layers',
  async (userId, { dispatch, getState }) => {
    try {
      dispatch(setGlobalLoader());
      const urlParams = new URLSearchParams(window.location.search);
      const mid = urlParams.get('mid'); // 'JohnDoe'

      const requestPath = window.location.pathname;
      const headers = {
        requestPath: requestPath,
      };
      const { userData } = getState()?.auth;
      // Define your data
      const postData = { ...userData, mid };

      const response = await axiosInstance.post(
        `/api/get_all_layers/${userId}`,
        postData,
        { headers }
      );
      dispatch(clearGlobalLoader());
      if (response?.data?.data && response?.data?.success) {
        return response?.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatch(clearGlobalLoader());

      dispatchErrorMessage(error, dispatch);
    }
  }
);

export const createLayer = createAsyncThunk(
  '/api/createLayer',
  async ({ userId, layerData, operation }, { dispatch }) => {
    // operation argument is used in fullfilled method or undo and redo operation
    const urlParams = new URLSearchParams(window.location.search);
    const mapId = urlParams.get('mid'); // 'JohnDoe'
    const postData = {
      ...layerData,
      mapId,
    };

    try {
      const response = await axiosInstance.post(
        `/api/create_layer/${userId}`,
        postData
      );
      if (response?.data?.data && response?.data?.success) {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'success',
            open: true,
          })
        );
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
    }
  }
);

export const updateLayer = createAsyncThunk(
  '/api/updateLayer',
  async ({ userId, layerData, operation }, { dispatch }) => {
    const layerId = layerData?._id;

    const urlParams = new URLSearchParams(window.location.search);
    const mapId = urlParams.get('mid'); // 'JohnDoe'

    const postData = {
      ...layerData,
      mapId,
    };
    try {
      const response = await axiosInstance.put(
        `/api/update_layer/${layerId}/${userId}`,
        postData
      );
      if (response?.data?.data && response?.data?.success) {
        if (operation !== undefined && operation !== '') {
          dispatch(
            setAlert({
              message: `${operation} successfully done!`,
              type: 'success',
              open: true,
            })
          );
        } else {
          dispatch(
            setAlert({
              message: response?.data?.msg,
              type: 'success',
              open: true,
            })
          );
        }
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
    }
  }
);

export const updateMultipleLayer = createAsyncThunk(
  '/api/updateMultipleLayer',
  async ({ userId, layersData, operation }, { dispatch }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const mapId = urlParams.get('mid'); // 'JohnDoe'

    try {
      const response = await axiosInstance.put(
        `/api/update_multiple_layer/${userId}`,
        layersData
      );
      if (response?.data?.data && response?.data?.success) {
        if (operation !== undefined && operation !== '') {
          dispatch(
            setAlert({
              message: `${operation} successfully done!`,
              type: 'success',
              open: true,
            })
          );
        } else {
          dispatch(
            setAlert({
              message: response?.data?.msg,
              type: 'success',
              open: true,
            })
          );
        }
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
    }
  }
);

export const deleteLayer = createAsyncThunk(
  '/api/deleteLayer',
  async ({ userId, layerData, operation }, { dispatch }) => {
    const layerId = layerData?._id;
    try {
      const response = await axiosInstance.delete(
        `/api/delete_layer/${layerId}/${userId}`,
        layerData
      );
      if (response?.data?.data && response?.data?.success) {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
    }
  }
);

export const restoreLayer = createAsyncThunk(
  'layers/restoreLayer',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const layerId = payload?._id;
      const userId = payload?.userId;
      const response = await axiosInstance.put(
        `/api/restore_Layer/${layerId}/${userId}`,
        payload
      );
      if (response?.data?.data && response?.data?.success) {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
        dispatch(fetchLayers(userId)); 
        return response.data; 
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
        return rejectWithValue(response?.data?.msg);
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: error?.response?.data?.msg || error?.message,
          type: 'error',
          open: true,
        })
      );
      return rejectWithValue(error?.response?.data?.msg || error?.message);
    }
  }
);


export const splitLayers = createAsyncThunk(
  'layers/splitLayers',
  async (
    { originalMapId, newMapTitle, newMapDescription, selectedLayerIds, userId },
    { dispatch }
  ) => {
    try {
      dispatch(setGlobalLoader());

      const data = {
        originalMapId,
        newMapTitle,
        newMapDescription,
        selectedLayerIds,
        userId,
      };

      const response = await axiosInstance.post('api/split-layers', data);

      dispatch(clearGlobalLoader());

      if (response.data?.success) {
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
      dispatch(clearGlobalLoader());
    }
  }
);

export const createMarker = createAsyncThunk(
  '/api/createMarker',
  async ({ userId, markerPayload }, { dispatch }) => {
    const layerId = markerPayload?.layerId;

    try {
      const response = await axiosInstance.post(
        `/api/create_marker/${userId}/${layerId}`,
        markerPayload
      );
      if (response?.data?.data && response?.data?.success) {
        // dispatch(fetchLayers(userId));
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
    }
  }
);

export const updateMarker = createAsyncThunk(
  '/api/updateMarker',
  async ({ userId, markerPayload }, { dispatch }) => {
    const layerId = markerPayload?.layerId;
    const markerId = markerPayload?._id;
    try {
      const response = await axiosInstance.put(
        `/api/update_Marker/${userId}/${layerId}/${markerId}`,
        markerPayload
      );
      if (response?.data?.data && response?.data?.success) {
        // dispatch(fetchLayers(userId));
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
    }
  }
);

export const swapMarker = createAsyncThunk(
  '/api/swapMarker',
  async ({ userId, markerPayload }, { dispatch }) => {
    const layerId = markerPayload?.layerId;
    const markerId = markerPayload?._id;
    try {
      const response = await axiosInstance.put(
        `/api/swap_Marker/${userId}/${layerId}/${markerId}`,
        markerPayload
      );
      if (response?.data?.data && response?.data?.success) {
        // dispatch(fetchLayers(userId));
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
    }
  }
);


export const mergeMarkers = createAsyncThunk(
  'markers/mergeMarkers',
  async ({ selectedLayer1, selectedMarkers }, { dispatch }) => {
    try {
      const response = await axiosInstance.post('/api/merge_markers', {
        markerIds: selectedMarkers,
        targetLayerId: selectedLayer1,
      });

   
      if (response.data?.success) {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
        return response.data;


      } else {
        throw new Error(response.data?.msg || 'Failed to merge markers');
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: error.message || 'Error merging markers',
          type: 'error',
          open: true,
        })
      );
      throw error; 
    }
  }
);



export const moveMarker = createAsyncThunk(
  'api/moveMarker',
  async ({ markerId, oldLayerId, newLayerId }, { dispatch }) => {
    try {
      const response = await axiosInstance.post(
        '/api/move-marker',
        {
          markerId,
          oldLayerId,
          newLayerId,
        }
      );
      if (response?.data?.success) {

        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response.data.msg,
            type: 'error',
            open: true,
          })
        );
        throw new Error(response.data.msg);
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: error.message || 'An error occurred while moving the marker',
          type: 'error',
          open: true,
        })
      );
      throw error;
    }
  }
);





export const deleteMarker = createAsyncThunk(
  '/api/deleteMarker',
  async ({ userId, markerData }, { dispatch }) => {
    const markerId = markerData?._id;
    const layerId = markerData?.layerId;
    try {
      const response = await axiosInstance.put(
        `/api/delete_marker/${userId}/${layerId}/${markerId}`,
        markerData
      );
      if (response?.data?.data && response?.data?.success) {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
    }
  }
);

export const restoreMarker = createAsyncThunk(
  '/api/restoreMarker',
  async ({ userId, markerData }, { dispatch }) => {
    try {
      const markerId = markerData?._id;
      const layerId = markerData?.layerId;

      const response = await axiosInstance.put(
        `/api/restore_Marker/${userId}/${layerId}/${markerId}`,
        markerData
      );
      if (response?.data?.data && response?.data?.success) {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
    }
  }
);

export const createPolygon = createAsyncThunk(
  '/api/createPolygon',
  async ({ userId, polygonPayload }, { dispatch }) => {
    const layerId = polygonPayload?.layerId;
    try {
      const response = await axiosInstance.post(
        `/api/create_Polygon/${userId}/${layerId}`,
        polygonPayload
      );
      if (response?.data?.data && response?.data?.success) {
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
    }
  }
);

export const updatePolygon = createAsyncThunk(
  '/api/updatePolygon',
  async ({ userId, polygonPayload }, { dispatch }) => {
    const layerId = polygonPayload?.layerId;
    const polygonId = polygonPayload?._id;
    try {
      const response = await axiosInstance.put(
        `/api/update_Polygon/${userId}/${layerId}/${polygonId}`,
        polygonPayload
      );
      if (response?.data?.data && response?.data?.success) {
        // dispatch(fetchLayers(userId));
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
    }
  }
);

export const swapPolygon = createAsyncThunk(
  '/api/swapPolygon',
  async ({ userId, polygonPayload }, { dispatch }) => {
    const layerId = polygonPayload?.layerId;
    const polygonId = polygonPayload?._id;
    try {
      const response = await axiosInstance.put(
        `/api/swap_Polygon/${userId}/${layerId}/${polygonId}`,
        polygonPayload
      );
      if (response?.data?.data && response?.data?.success) {
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
    }
  }
);

export const deletePolygon = createAsyncThunk(
  '/api/deletePolygon',
  async ({ userId, polygonPayload }, { dispatch }) => {
    const polygonId = polygonPayload?._id;
    const layerId = polygonPayload?.layerId;
    try {
      const response = await axiosInstance.put(
        `/api/delete_polygon/${userId}/${layerId}/${polygonId}`,
        polygonPayload
      );
      if (response?.data?.data && response?.data?.success) {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
        return Promise.reject(new Error(response?.data?.msg));

      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
      return Promise.reject(error);

    }
  }
);

export const restorePolygon = createAsyncThunk(
  '/api/restorePolygon',
  async ({ userId, polygonPayload }, { dispatch }) => {
    const polygonId = polygonPayload?._id;
    const layerId = polygonPayload?.layerId;
    try {
      const response = await axiosInstance.put(
        `/api/restore_polygon/${userId}/${layerId}/${polygonId}`,
        polygonPayload
      );
      if (response?.data?.data && response?.data?.success) {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
        // dispatch(fetchLayers(userId));
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
    }
  }
);

export const mapSlice = createSlice({
  name: 'map',
  initialState: {
    layers: [],
    map: {},
    msalData: {},
    history: [], 
    future: [], 
    status: 'idle',
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLayers.fulfilled, (state, action) => {
        state.layers = action.payload?.data?.layers
          ? action.payload?.data?.layers
          : [];
        state.map = action.payload?.data?.map ? action.payload?.data?.map : {};
      })
      .addCase(createLayer.fulfilled, (state, action) => {
        const operation = action?.meta?.arg?.operation;

        // Create a copy of the layer object to push to history or future
        if (operation === 'undo') {
          state.future.push({
            operation: 'added',
            layer: JSON.parse(JSON.stringify(action?.payload.data)),
          });
          state.history.pop();
        } else if (operation === 'redo') {
          state.history.push({
            operation: 'added',
            layer: JSON.parse(JSON.stringify(action?.payload.data)),
          });
          state.future.pop();
        } else {
          state.history.push({
            operation: 'added',
            layer: JSON.parse(JSON.stringify(action?.payload.data)),
          });
        }

        state.layers.push(action?.payload.data);
      })
      .addCase(updateLayer.fulfilled, (state, action) => {
        const updatedLayerData = action.payload.data;
        const operation = action?.meta?.arg?.operation;
        // Find the index of the layer to update
        const index = state.layers.findIndex(
          (layer) => layer._id === updatedLayerData?._id
        );

        if (index !== -1) {
          // Create a copy of the layer object to push to history or future
          if (operation === 'undo') {
            state.future.push({
              operation: 'update',
              layer: JSON.parse(JSON.stringify(state.layers[index])), // Deep copy to avoid mutations
            });
            state.history.pop();
          } else if (operation === 'redo') {
            state.history.push({
              operation: 'update',
              layer: JSON.parse(JSON.stringify(state.layers[index])), // Deep copy to avoid mutations
            });

            state.future.pop();
          } else {
            state.history.push({
              operation: 'update',
              layer: JSON.parse(JSON.stringify(state.layers[index])), // Deep copy to avoid mutations
            });
          }

          // Update the layer with updatedLayerData using immer's produce
          state.layers[index] = {
            ...state.layers[index],
            ...updatedLayerData,
          };
        }
      })
      .addCase(deleteLayer.fulfilled, (state, action) => {

        const updatedLayerData = action.payload.data;
        const operation = action?.meta?.arg?.operation;
        // Find the index of the layer to update
        const index = state.layers.findIndex(
          (layer) => layer._id === updatedLayerData._id
        );

        if (index !== -1) {
          // Create a copy of the layer object to push to history or future
          if (operation === 'undo') {
            state.future.push({
              operation: 'deleted',
              layer: JSON.parse(JSON.stringify(state.layers[index])), // Deep copy to avoid mutations
            });
            state.history.pop();
          } else if (operation === 'redo') {
            state.history.push({
              operation: 'deleted',
              layer: JSON.parse(JSON.stringify(state.layers[index])), // Deep copy to avoid mutations
            });

            state.future.pop();
          } else {
            state.history.push({
              operation: 'deleted',
              layer: JSON.parse(JSON.stringify(state.layers[index])), // Deep copy to avoid mutations
            });
          }
          // Update the layer with updatedLayerData using immer's produce
          state.layers[index] = {
            ...state.layers[index],
            ...updatedLayerData,
          };
        }
      })
      .addCase(restoreLayer.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(restoreLayer.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const restoredLayer = action.payload.data;
        state.layers = state.layers.map((layer) =>
          layer._id === restoredLayer._id ? { ...layer, status: 'active' } : layer
        );
      })
      .addCase(restoreLayer.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      .addCase(splitLayers.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(splitLayers.fulfilled, (state, action) => {
        state.success = 'succeeded';
        // Update state based on the action payload if necessary
      })
      .addCase(splitLayers.rejected, (state, action) => {
        state.error = action.error.message;
      })

      .addCase(createMarker.fulfilled, (state, action) => {
        const updatedLayerData = action?.payload?.data;
        // Find the index of the layer to update
        const index = state.layers.findIndex(
          (layer) => layer._id === updatedLayerData?._id
        );

        if (index !== -1) {
          // Create a copy of the layer object to push to history
          state.history.push({
            operation: 'update',
            layer: JSON.parse(JSON.stringify(state.layers[index])), // Deep copy to avoid mutations
          });

          // Update the layer with updatedLayerData using immer's produce
          state.layers[index] = { ...state.layers[index], ...updatedLayerData };
        }
      })

   
      .addCase(mergeMarkers.fulfilled, (state, action) => {
        const operation = action?.meta?.arg?.operation;
        const { targetLayer, oldLayersDetails } = action.payload;
        let historyLayers = [];
    
        const targetLayerIndex = state.layers.findIndex(
            (layer) => layer._id === targetLayer._id
        );
        if (targetLayerIndex !== -1) {
            historyLayers.push(JSON.parse(JSON.stringify(state.layers[targetLayerIndex])));
            state.layers[targetLayerIndex] = {
                ...state.layers[targetLayerIndex],
                ...targetLayer,
            };
        }
    
        oldLayersDetails.forEach((oldLayer) => {
            const oldLayerIndex = state.layers.findIndex(
                (layer) => layer._id === oldLayer._id
            );
            if (oldLayerIndex !== -1) {
                historyLayers.push(JSON.parse(JSON.stringify(state.layers[oldLayerIndex])));
                state.layers[oldLayerIndex] = {
                    ...state.layers[oldLayerIndex],
                    ...oldLayer,
                };
            }
        });
    
        if (operation === 'undo') {
            state.future.push({
                operation: 'swap',
                layers: historyLayers,
            });
            state.history.pop();
        } else if (operation === 'redo') {
            state.history.push({
                operation: 'swap',
                layers: historyLayers,
            });
            state.future.pop();
        } else {
            state.history.push({
                operation: 'swap',
                layers: historyLayers,
            });
        }
    })
    
    
      


     


      .addCase(updateMarker.fulfilled, (state, action) => {
        const updatedLayerData = action.payload.data;
        // Find the index of the layer to update
        const index = state.layers.findIndex(
          (layer) => layer._id === updatedLayerData?._id
        );

        if (index !== -1) {
          // Create a copy of the layer object to push to history
          state.history.push({
            operation: 'update',
            layer: JSON.parse(JSON.stringify(state.layers[index])), // Deep copy to avoid mutations
          });

          // Update the layer with updatedLayerData using immer's produce
          state.layers[index] = {
            ...state.layers[index],
            ...updatedLayerData,
          };
        }
      })
      .addCase(updateMultipleLayer.fulfilled, (state, action) => {
        const operation = action?.meta?.arg?.operation;
        const updatedLayerData = action.payload.data;
        let historyLayers = [];
        updatedLayerData?.length > 0 &&
          updatedLayerData?.filter((updatelayer) => {
            // Find the index of the layer to update
            const Index = state.layers.findIndex(
              (layer) => layer._id === updatelayer?._id
            );

            if (Index !== -1) {
              // Create a copy of the layer object to push to history

              historyLayers.push(
                JSON.parse(JSON.stringify(state.layers[Index]))
              );
              // Update the layer with updatedLayerData using immer's produce
              state.layers[Index] = {
                ...state.layers[Index],
                ...updatelayer,
              };
            }
          });

        // Create a copy of the layer object to push to history or future
        if (operation === 'undo') {
          state.future.push({
            operation: 'swap',
            layers: historyLayers, // Deep copy to avoid mutations
          });
          state.history.pop();
        } else if (operation === 'redo') {
          state.history.push({
            operation: 'swap',
            layers: historyLayers, // Deep copy to avoid mutations
          });
          state.future.pop();
        } else {
          state.history.push({
            operation: 'swap',
            layers: historyLayers, // Deep copy to avoid mutations
          });
        }
      })



      .addCase(swapMarker.fulfilled, (state, action) => {
        const updatedLayerData = action.payload.data;
        let historyLayers = [];
        
        updatedLayerData?.length > 0 &&
          updatedLayerData?.filter((updatelayer) => {
            // Find the index of the layer to update
            const Index = state.layers.findIndex(
              (layer) => layer._id === updatelayer?._id
            );

            if (Index !== -1) {
              // Create a copy of the layer object to push to history

              historyLayers.push(
                JSON.parse(JSON.stringify(state.layers[Index]))
              );
              // Update the layer with updatedLayerData using immer's produce
              state.layers[Index] = {
                ...state.layers[Index],
                ...updatelayer,
              };
            }
          });

        state.history.push({
          operation: 'swap',
          layers: historyLayers, // Deep copy to avoid mutations
        });
      })

      

      .addCase(moveMarker.fulfilled, (state, action) => {
        const { oldLayer, newLayer } = action.payload;
        let historyLayers = [];
        
        const oldLayerIndex = state.layers.findIndex(
          (layer) => layer._id === oldLayer?._id
        );
        
        const newLayerIndex = state.layers.findIndex(
          (layer) => layer._id === newLayer?._id
        );
        
        if (oldLayerIndex !== -1 && newLayerIndex !== -1) {
          historyLayers.push(
            JSON.parse(JSON.stringify(state.layers[oldLayerIndex])),
            JSON.parse(JSON.stringify(state.layers[newLayerIndex]))
          );
          
          state.layers[oldLayerIndex] = {
            ...state.layers[oldLayerIndex],
            ...oldLayer, 
          };
      
          state.layers[newLayerIndex] = {
            ...state.layers[newLayerIndex],
            ...newLayer, 
          };
        }
      
        state.history.push({
          operation: 'swap',
          layers: historyLayers, 
        });
      })
      
      
      

      

      .addCase(deleteMarker.fulfilled, (state, action) => {
        const updatedLayerData = action.payload.data;
        // Find the index of the layer to update
        const index = state.layers.findIndex(
          (layer) => layer._id === updatedLayerData?._id
        );

        if (index !== -1) {
          // Create a copy of the layer object to push to history
          state.history.push({
            operation: 'update',
            layer: JSON.parse(JSON.stringify(state.layers[index])), // Deep copy to avoid mutations
          });

          // Update the layer with updatedLayerData using immer's produce
          state.layers[index] = {
            ...state.layers[index],
            ...updatedLayerData,
          };
        }
      })

      .addCase(restoreMarker.fulfilled, (state, action) => {
        const restoredMarkerData = action.payload.data;
        const index = state.layers.findIndex(
          (layer) => layer._id === restoredMarkerData?.layerId
        );
    
        if (index !== -1) {
          state.history.push({
            operation: 'update',
            layer: JSON.parse(JSON.stringify(state.layers[index])), 
          });
    
          const markerIndex = state.layers[index].markers.findIndex(
            (marker) => marker._id === restoredMarkerData?._id
          );
    
          if (markerIndex !== -1) {
            state.layers[index].markers[markerIndex] = {
              ...state.layers[index].markers[markerIndex],
              ...restoredMarkerData,
              deleted: false, 
            };
          } else {
            state.layers[index].markers.push({
              ...restoredMarkerData,
              deleted: false,
            });
          }
        }
      })
    

      // Polygon Operations
      .addCase(createPolygon.fulfilled, (state, action) => {
        const updatedLayerData = action.payload.data;
        // Find the index of the layer to update
        const index = state.layers.findIndex(
          (layer) => layer._id === updatedLayerData?._id
        );

        if (index !== -1) {
          // Create a copy of the layer object to push to history
          state.history.push({
            operation: 'update',
            layer: JSON.parse(JSON.stringify(state.layers[index])), // Deep copy to avoid mutations
          });

          // Update the layer with updatedLayerData using immer's produce
          state.layers[index] = {
            ...state.layers[index],
            ...updatedLayerData,
          };
        }
      })
      .addCase(updatePolygon.fulfilled, (state, action) => {
        const updatedLayerData = action.payload.data;
        // Find the index of the layer to update
        const index = state.layers.findIndex(
          (layer) => layer._id === updatedLayerData?._id
        );

        if (index !== -1) {
          // Create a copy of the layer object to push to history
          state.history.push({
            operation: 'update',
            layer: JSON.parse(JSON.stringify(state.layers[index])), // Deep copy to avoid mutations
          });

          // Update the layer with updatedLayerData using immer's produce
          state.layers[index] = {
            ...state.layers[index],
            ...updatedLayerData,
          };
        }
      })
      .addCase(swapPolygon.fulfilled, (state, action) => {
        const updatedLayerData = action.payload.data;
        let historyLayers = [];
        updatedLayerData?.length > 0 &&
          updatedLayerData?.filter((updatelayer) => {
            // Find the index of the layer to update
            const Index = state.layers.findIndex(
              (layer) => layer._id === updatelayer?._id
            );

            if (Index !== -1) {
              // Create a copy of the layer object to push to history

              historyLayers.push(
                JSON.parse(JSON.stringify(state.layers[Index]))
              );
              // Update the layer with updatedLayerData using immer's produce
              state.layers[Index] = {
                ...state.layers[Index],
                ...updatelayer,
              };
            }
          });

        state.history.push({
          operation: 'swap',
          layers: historyLayers, // Deep copy to avoid mutations
        });
      })
      .addCase(deletePolygon.fulfilled, (state, action) => {
        const updatedLayerData = action.payload.data;
        const index = state.layers.findIndex(
          (layer) => layer._id === updatedLayerData?._id
        );

        if (index !== -1) {
          // Create a copy of the layer object to push to history
          state.history.push({
            operation: 'update',
            layer: JSON.parse(JSON.stringify(state.layers[index])), // Deep copy to avoid mutations
          });

          // Update the layer with updatedLayerData using immer's produce
          state.layers[index] = {
            ...state.layers[index],
            ...updatedLayerData,
          };
        }
      })
      .addCase(restorePolygon.fulfilled, (state, action) => {
        const updatedLayerData = action.payload.data;
        const index = state.layers.findIndex(
          (layer) => layer._id === updatedLayerData?._id
        );

        if (index !== -1) {
          state.history.push({
            operation: 'update',
            layer: JSON.parse(JSON.stringify(state.layers[index])),
          });

          state.layers[index] = {
            ...state.layers[index],
            ...updatedLayerData,
          };
        }
      });



  },
});

export const {} = mapSlice.actions;
export default mapSlice.reducer;
