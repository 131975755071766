// globalAlertSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const dispatchErrorMessage = (error, dispatch) => {
  console.log(error, 'error');
  if (error?.code) {
    dispatch(
      setAlert({
        message: error?.response?.data?.msg || error?.message,
        type: 'error',
        open: true,
      })
    );
  } else {
    dispatch(
      setAlert({
        message: error?.response?.data?.msg,
        type: 'error',
        open: true,
      })
    );
  }
};

export const globalAppSlice = createSlice({
  name: 'globalApp',
  initialState: {
    message: null,
    type: null, // 'success' or 'error'
    open: false,
    isAppLoading: false,
  },
  reducers: {
    setAlert: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.open = true;
    },
    clearAlert: (state) => {
      state.message = null;
      state.type = null;
      state.open = false;
    },
    setGlobalLoader: (state) => {
      state.isAppLoading = true;
    },
    clearGlobalLoader: (state) => {
      state.isAppLoading = false;
    },
  },
});

export const { setAlert, clearAlert, setGlobalLoader, clearGlobalLoader } =
  globalAppSlice.actions;

export default globalAppSlice.reducer;
