import { createTheme } from '@mui/material/styles';
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    background: {
      default: '#ebf5ff',
    },
    primary: {
      main: '#007BFF',
    },
  },
  typography: {
    htmlFontSize: 16,
    allVariants: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          'scrollbar-width': 'thin',
        },
        '*::-webkit-scrollbar': {
          width: '6px',
          height: '3px',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px #E8EDF3',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: ' #B4B9C5',
          outline: '1px solid #B4B9C5',
          borderRadius: '100px',
        },
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        input: {
          fontFamily: ['Poppins', 'sans-serif'].join(','),
          fontSize: '1rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: ['Poppins', 'sans-serif'].join(','),
          borderRadius: '0.625rem',
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        '&:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 100px #000 inset',
          '-webkit-text-fill-color': '#fff',
        },
      },
    },
  },
});

export default theme;
