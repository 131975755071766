import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle'; // Import DialogTitle
import DialogContent from '@mui/material/DialogContent'; // Import DialogContent
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

function SessionExpiredPopup({ isOpen, onClose }) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Session Expired</DialogTitle> {/* Use DialogTitle */}
      <DialogContent>
        {' '}
        {/* Use DialogContent */}
        Your session has expired. Please log in again.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          LogIn
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SessionExpiredPopup;
