// GlobalAlert.jsx
import React from 'react';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useSelector, useDispatch } from 'react-redux'; // assuming Redux is used
import { clearAlert } from '../../store/globalAppSlice';

const GlobalAlert = () => {
  const dispatch = useDispatch();
  const { open, message, type } = useSelector((state) => state.globalApp);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(clearAlert());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
     {open ? (
        <Alert
          onClose={handleClose}
          severity={type ? type : 'info'}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      ) : null}
    </Snackbar>
  );
};

export default GlobalAlert;
