import { createAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../api/axios';
import { setAlert } from './globalAppSlice';

export const getUserListing = createAsyncThunk(
  '/api/getUserListing',
  async (_, { dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await axiosInstance.get(`/api/list_of_users`);
      if (response?.data?.data && response?.data?.success) {
        return response?.data;
      } else {
        /*  dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
        */
        return false;
      }
    } catch (error) {
      if (error?.code === 'ERR_NETWORK') {
        dispatch(
          setAlert({
            message: 'Network Error! Please check your network connection',
            type: 'error',
            open: true,
          })
        );
      }
      return false;
    }
  }
);

export const createNewUser = createAsyncThunk(
  '/api/createNewUser',
  async (payload, { dispatch }) => {
    try {
      const response = await axiosInstance.post(
        `/api/user_create_by_admin`,
        payload
      );
      if (response?.data?.success) {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
        dispatch(getUserListing());
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: error?.response?.data?.msg,
          type: 'error',
          open: true,
        })
      );
    }
  }
);




export const getLayersByMapId = createAsyncThunk(
  '/api/getLayersByMapId',
  async ({ mapId, search }, { dispatch }) => {
    try {
      let url = `/api/map/${mapId}`;
      if (search) {
        url += `?search=${encodeURIComponent(search)}`;
      }

      const response = await axiosInstance.get(url);
      if (response?.data && response.status === 200) {
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.message || 'Failed to fetch layers',
            type: 'error',
            open: true,
          })
        );
        return false;
      }
    } catch (error) {
      if (error?.code === 'ERR_NETWORK') {
        dispatch(
          setAlert({
            message: 'Network Error! Please check your network connection',
            type: 'error',
            open: true,
          })
        );
      } else {
        dispatch(
          setAlert({
            message: error.message || 'Failed to fetch layers',
            type: 'error',
            open: true,
          })
        );
      }
      return false;
    }
  }
);

export const deleteLayerPermanently = createAsyncThunk(
  'api/deleteLayerPermanently',
  async (layerId, { dispatch }) => {
    try {
      const response = await axiosInstance.delete(`/api/permanent-delete/${layerId}`);

      if (response?.data?.success) {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.message || 'Failed to delete the layer',
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: error?.response?.data?.message || 'An error occurred while deleting the layer',
          type: 'error',
          open: true,
        })
      );
      throw error;
    }
  }
);



export const updateUserDetails = createAsyncThunk(
  '/api/updateUserInformation',
  async (payload, { dispatch }) => {
    try {
      const userId = payload?._id;
      const response = await axiosInstance.put(
        `/api/update_user_information/${userId}`,
        payload
      );
      if (response?.data && response?.data?.success) {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
        dispatch(getUserListing());
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: error?.response?.data?.msg,
          type: 'error',
          open: true,
        })
      );
    }
  }
);

export const deleteUser = createAsyncThunk(
  '/api/deleteUserByAdmin',
  async (userId, { dispatch }) => {
    try {
      let response = await axiosInstance.delete(`/api/delete_user/${userId}`);

      if (response?.data && response?.data?.success) {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
        dispatch(getUserListing());
        return response.data;
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: error?.response?.data?.msg,
          type: 'error',
          open: true,
        })
      );
    }
  }
);

export const importMapDataApi = createAsyncThunk(
  '/api/importMapData',
  async (file, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));

      let formData = new FormData();
      formData.append('file', file);
      const response = await axiosInstance.post(
        `/api/importMapData`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response?.data?.success) {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'success',
            open: true,
          })
        );
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: error?.response?.data?.msg,
          type: 'error',
          open: true,
        })
      );
    }
  }
);

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    usersList: [],
    layersList: [],
    isLoading: false,
  },
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserListing.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.usersList = action.payload?.data;
        state.isLoading = false;
      }
    });

   

    builder.addCase(getLayersByMapId.fulfilled, (state, action) => {
      if (action.payload) {
        state.layersList = action.payload;
      }
      state.isLoading = false;
    })

    
    builder.addCase(deleteLayerPermanently.fulfilled, (state, action) => {
      state.isLoading = false;
      state.layersList = (state.layersList || []).filter(
        (layer) => layer._id !== action.payload
      );
    })
    



    builder.addCase(importMapDataApi.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    
  },
});

export const { setIsLoading } = adminSlice.actions;
export default adminSlice.reducer;
