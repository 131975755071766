import React, { lazy, Suspense, useState, useEffect } from 'react';
import axiosInstance from './api/axios';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
// import AppLoading from './AppLoading'
//import Layout from "../components/Layout";
import Splash from './components/Splash';
import SessionExpiredPopup from './formField/SessionExpiredPopup';
import UserDataNotFound from './formField/userDataNotFound';
import LoadingBackdrop from './formField/LoadingBackdrop';
import WithAdminRestriction from './components/Admin/WithAdminRestriction';
import { useMsal } from '@azure/msal-react';
import GlobalAlert from './components/common/GlobalAlert';
// import { getRedirectTo } from '../selectors'

const Login = lazy(() => import('./components/login'));
const AllMaps = lazy(() => import('./components/AllMaps'));
const Admin = lazy(() => import('./components/Admin/AdminDashboard'));
const Map = lazy(() => import('./components/Map'));

function App() {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const accessToken = localStorage.getItem('accessToken');
  const userRole = localStorage.getItem('role');
  const RestrictedAdminDashboard = WithAdminRestriction(Admin);
  const [isSessionExpiredPopupOpen, setSessionExpiredPopupOpen] =
    useState(false);
  const [isUserNotfoundPopup, setIsUserNotfoundPopup] = useState(false);
  // const { isAppLoading } = useSelector((state) => state.globalApp);

  // Add an Axios response interceptor to handle invalid tokens
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 500) {
        // Invalid token detected, show the session expired popup
        setSessionExpiredPopupOpen(true);
      }

      
      if (error.response && error.response.status === 204) {
        // user data not found
        setIsUserNotfoundPopup(true);
      }
      return Promise.reject(error);
    }
  );

  // Function to close the popup
  const closeSessionExpiredPopup = () => {
    setSessionExpiredPopupOpen(false);
    localStorage.clear();
    navigate('/login'); // Redirect to the login page
  };

  const handleLogout = () => {
    setIsUserNotfoundPopup(false);
    if (accounts?.length > 0) {
      const homeAccountId = accounts[0]?.homeAccountId;
      const logoutRequest = {
        account: instance.getAccountByHomeId(homeAccountId),
        postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_URI,
      };
      instance.logoutRedirect(logoutRequest);
      localStorage.clear();
      // navigate('/login'); // Redirect to the login page
    }
  };

  const RestrictedLogin = () => {
    // const redirectTo = useSelector(getRedirectTo)
    if (accessToken && userRole === 'admin') {
      return <Navigate to="/admin/dashboard" />;
    }

    if (accessToken && (userRole === 'viewer' || userRole === 'editor')) {
      return <Navigate to="/maps" />;
    }

    return <Login />;
  };

  const RestrictedMap = () => {
    // const redirectTo = useSelector(getRedirectTo)
    if (accessToken) {
      return <Map />;
    } else {
      return <Navigate to="/login" />;
    }
  };

  const RestrictedAllMaps = () => {
    // const redirectTo = useSelector(getRedirectTo)
    if (accessToken) {
      return <AllMaps />;
    } else {
      return <Navigate to="/login" />;
    }
  };

  return (
    <Suspense fallback={<Splash />}>
      <div>
        <GlobalAlert />

        <SessionExpiredPopup
          isOpen={isSessionExpiredPopupOpen}
          onClose={closeSessionExpiredPopup}
        />
        <UserDataNotFound isOpen={isUserNotfoundPopup} onClose={handleLogout} />
      </div>
      <Routes>
        <Route path="/login" element={<RestrictedLogin />} />
        <Route path="/admin/dashboard" element={<RestrictedAdminDashboard />} />
        <Route path="/map" element={<RestrictedMap />} />
        <Route path="/maps" element={<RestrictedAllMaps />} />
        <Route
          path="/"
          element={
            <Navigate
              to={
                accessToken
                  ? userRole === 'admin'
                    ? '/admin/dashboard'
                    : '/maps'
                  : '/login'
              }
            />
          }
        />
        <Route path="*" element={<Navigate to={'/login'} />} />

      </Routes>
    </Suspense>
  );
}

export default App;
