import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../api/axios';

export const fetchZohoAccounts = async (searchValue) => {
  try {
    const response = await axiosInstance.post(`/api/searchZohoAccounts`, {
      searchValue: searchValue,
    });

    if (response?.data?.data && response?.data?.success) {
      return response?.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};

export const searchZohoAccounts = createAsyncThunk(
  '/api/searchZohoAccounts',
  async (searchValue, { dispatch }) => {
    try {
      const response = await axiosInstance.post(`/api/searchZohoAccounts`, {
        searchValue: searchValue,
      });

      if (response?.data?.data && response?.data?.success) {
        return response?.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const zohoSlice = createSlice({
  name: 'zoho',
  initialState: {
    zohoAccounts: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchZohoAccounts.fulfilled, (state, action) => {
      if (action.payload?.data) {
        let zohoAccounts = [];
        const zohoAccountsData = action.payload?.data;
        zohoAccountsData?.filter((zohoAccount, index) => {
          const accountData = {
            id: zohoAccount?.id,
            label: zohoAccount?.Account_Name,
            email: zohoAccount?.Email,
            Location_Map_Layer: zohoAccount?.Location_Map_Layer,
          };
          zohoAccounts.push(accountData);
        });
        state.zohoAccounts = zohoAccounts;
      }
    });
  },
});

export default zohoSlice.reducer;
