import { configureStore, compose } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import authReducer from './authSlice';
import adminReducer from './adminSlice';
import mapReducer from './mapSlice';
import allMapsReducer from './allMapsSlice';
import zohoReducer from './zohoSlice';
import globalAppReducer from './globalAppSlice';

const devToolsExtension =
  process.env.NODE_ENV !== 'production' && typeof window === 'object'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const reducer = {
  auth: authReducer,
  admin: adminReducer,
  map: mapReducer,
  allMaps: allMapsReducer,
  zoho: zohoReducer,
  globalApp: globalAppReducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: devToolsExtension,
});

export default store;
