// mapsSlice.js
import { createAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../api/axios';
import { clearGlobalLoader, setGlobalLoader, setAlert } from './globalAppSlice';
import { setUserMapLocation } from './authSlice';

export const fetchMaps = createAsyncThunk(
  'maps/fetchMaps',
  async (
    { page, limit, search, userId, tab, isAdminDashboard },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const url = isAdminDashboard
        ? `/api/get_all_maps?isAdminDashboard=true&search=${search}`
        : `/api/get_all_maps?page=${page}&limit=${limit}&search=${search}&userId=${userId}&tab=${tab}`;

      const response = await axiosInstance.get(url);

      if (response?.data?.data && response?.data?.success) {
        return response.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
        return rejectWithValue(response?.data?.msg);
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: error?.response?.data?.msg || error?.message,
          type: 'error',
          open: true,
        })
      );
      return rejectWithValue(error?.response?.data?.msg || error?.message);
    }
  }
);


export const createMap = createAsyncThunk('maps/createMap', async (newMap) => {
  const response = await axiosInstance.post('/api/create_map', newMap);
  return response.data;
});

export const updateMap = createAsyncThunk(
  'maps/updateMap',
  async ({ id, updatedData }) => {
    const response = await axiosInstance.put(`/api/update_map/${id}`, updatedData);
    return response.data;
  }
);

export const deleteMap = createAsyncThunk(
  'maps/deleteMap',
  async (mapId, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/api/delete_map/${mapId}`);
      if (response?.data && response?.data?.success) {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
        return mapId; // return the id of the deleted map
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
        return rejectWithValue(response?.data?.msg);
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: error?.response?.data?.msg || error?.message,
          type: 'error',
          open: true,
        })
      );
      return rejectWithValue(error?.response?.data?.msg || error?.message);
    }
  }
);

export const restoreMap = createAsyncThunk(
  'maps/restoreMap',
  async (mapId, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/api/restore_map/${mapId}`);
      if (response?.data && response?.data?.success) {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
        return mapId; 
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
        return rejectWithValue(response?.data?.msg);
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: error?.response?.data?.msg || error?.message,
          type: 'error',
          open: true,
        })
      );
      return rejectWithValue(error?.response?.data?.msg || error?.message);
    }
  }
);



export const deleteMapPermanently = createAsyncThunk(
  'maps/deleteMapPermanently',
  async ({ mapId }, { dispatch }) => {
    try {
      const response = await axiosInstance.delete(`/api/permanent_delete/${mapId}`);

      if (response?.data && response.status === 200) {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
       
       return mapId
       
       
        
      } else {
        dispatch(
          setAlert({
            message: response?.data?.message || 'Failed to permanently delete map',
            type: 'error',
            open: true,
          })
        );
        return false;
      }
    } catch (error) {
      if (error?.code === 'ERR_NETWORK') {
        dispatch(
          setAlert({
            message: 'Network Error! Please check your network connection',
            type: 'error',
            open: true,
          })
        );
      } else {
        dispatch(
          setAlert({
            message: error.message || 'Failed to permanently delete map',
            type: 'error',
            open: true,
          })
        );
      }
      return false;
    }
  }
);


export const mergeMaps = createAsyncThunk(
  'layers/mergeMaps',
  async ({ mapIds, newMapTitle, newMapDescription, userId }, { dispatch }) => {
    try {
      dispatch(setGlobalLoader());

      const data = {
        mapIds,
        newMapTitle,
        newMapDescription,
        userId,
      };

      const response = await axiosInstance.post('api/merge_map', data);

      dispatch(clearGlobalLoader());

      if (response.data?.success) {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
        return { ...response.data, mapIds };
      } else {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      dispatchErrorMessage(error, dispatch);
      dispatch(clearGlobalLoader());
    }
  }
);
// Create the slice
const allMapsSlice = createSlice({
  name: 'allMaps',
  initialState: {
    maps: [],
    status: 'idle',
    error: null,
    currentPage: 1,
    totalPages: 1,
    
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetch maps
      .addCase(fetchMaps.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMaps.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.maps = action.payload?.data;
        state.currentPage = action.payload?.currentPage;
        state.totalPages = action.payload?.totalPages;
      })
      .addCase(fetchMaps.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createMap.fulfilled, (state, action) => {
        state.maps.unshift(action.payload?.data);
      })
      .addCase(updateMap.fulfilled, (state, action) => {
        const { id, updatedData } = action.payload;
        const existingMap = state.maps.find((map) => map.id === id);
        if (existingMap) {
          Object.assign(existingMap, updatedData);
        }
      })
      // Handle delete map
      .addCase(deleteMap.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteMap.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          state.status = 'succeeded';
          state.maps = state.maps.filter((map) => map._id !== action.payload);
        }
      })
      .addCase(deleteMap.rejected, (state, action) => {
        state.status = 'failed';
      })


      .addCase(deleteMapPermanently.fulfilled, (state, action) => {
        if (action.payload) {
          const mapId = action.payload;
          state.maps = state.maps.filter((map) => map._id !== mapId);
        }
      })
  

      .addCase(restoreMap.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(restoreMap.fulfilled, (state, action) => {
        const mapToRestore = state.maps.find(map => map._id === action.payload);
        
        if (mapToRestore) {
          mapToRestore.status = 'active'; 
        }
        state.status = 'succeeded';
      })
      .addCase(restoreMap.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to restore map';
      })


      .addCase(mergeMaps.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { mapIds } = action.payload;
        state.maps.unshift(action.payload?.data);

        state.maps = state.maps.filter((map) => !mapIds.includes(map._id));
      })
   
      .addCase(mergeMaps.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default allMapsSlice.reducer;
