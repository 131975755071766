import { createAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../api/axios';
import { setAlert, dispatchErrorMessage } from './globalAppSlice';
export const getUserData = createAsyncThunk(
  '/api/getUserData',
  async (navigate, { dispatch }) => {
    try {
      const response = await axiosInstance.post(`/api/getUserData`);

      if (
        response?.data?.data?.user &&
        response?.data?.msg === 'Logged in successfully'
      ) {
        const userRole = response?.data?.data?.user?.role;
        localStorage.setItem('role', userRole);
        if (navigate !== undefined) {
          if (userRole === 'admin') {
            navigate('/admin/dashboard');
          } else if (userRole === 'viewer' || userRole === 'editor') {
            navigate('/map');
          }
        }
        return response?.data;
      } else {
        dispatch(
          setAlert({
            message: response?.data?.msg,
            type: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      //dispatchErrorMessage(error, dispatch);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    login: false,
    userData: {
      center: { lat: 37.7749, lng: -122.4194 },
      state: 'California',
      country: 'United States',
    },
    accessToken: '',
    msalData: {},
  },
  reducers: {
    login: (state) => {
      state.value += 1;
    },
    setUserMapLocation: (state, action) => {
      state.userData.center = action.payload.center;
      state.userData.country = action.payload.country;
      state.userData.state = action.payload.state;
    },
    setMsalData: (state, action) => {
      state.msalData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.login = true;
      state.userData = { ...state.userData, ...action.payload?.data?.user };
      state.accessToken = action.payload?.data?.accessToken;
    });
  },
});

export const { login, setMsalData, setUserMapLocation } = authSlice.actions;
export default authSlice.reducer;
